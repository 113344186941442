/* Add animation styles */
@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.menu {
  position: fixed;
  top: 74px;
  right: 0;
  left: 0;
  height: 100vh;
  padding: 25px 40px;
  background-color: #111;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: "BebasNeue", Arial, sans-serif;
  font-size: 52px;
  line-height: 57px;
  font-weight: 500;
  color: white;
  animation: slide-in 0.5s forwards;
}

.menu--hidden {
  animation: slide-out 0.5s forwards;
}

.menu-button_first {
  color: inherit;
  text-decoration: none;
  opacity: 0;
  animation: load-button 1s ease-in-out; /* animation name, duration, timing function */
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}

.menu-button_second {
  color: inherit;
  text-decoration: none;
  opacity: 0; /* initial state: invisible */
  animation: load-button 2s ease-in-out; /* animation name, duration, timing function */
  animation-delay: 0.4s; /* delay animation by 2 seconds */
  animation-fill-mode: forwards; /* keep the final state */
}

@keyframes load-button {
  0% {
    opacity: 0;
    translate: 0 30px;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .menu {
    top: 55px;
    padding: 15px 25px;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .menu {
    padding: 7px 20px;
  }
}
