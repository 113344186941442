.header {
  background-color: #111;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(251, 246, 246, 0.42);
  padding: 10px 40px;
}

.header-logo {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-button {
  display: flex;
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: "BebasNeue", Arial, sans-serif;
  font-size: 35px;
  line-height: 40px;
  font-weight: 400;
  color: whitesmoke;
  cursor: pointer;
}

.header-button:hover {
  color: #6563ff;
}

.header-nav {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 5px 40px;
  }

  .header-logo {
    width: 41px;
    height: 41px;
  }

  .header-buttons {
    gap: 0;
  }

  .header-nav {
    width: 37px;
    height: 37px;
  }

  .header-button {
    font-size: 30px;
    line-height: 35px;
  }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .header {
    padding: 5px 25px;
  }
}
