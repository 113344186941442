.project_section {
  padding: 10px 40px 50px;
  background-color: #111;
}

.project_header {
  margin: 40px 0 20px;
  font-family: "BebasNeue", Arial, sans-serif;
  font-size: 52px;
  line-height: 57px;
  font-weight: 400;
  color: rgba(231, 230, 230, 0.738);
}

.project_items {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}

@media screen and (max-width: 654px) {
  .project_section {
    padding: 5px 20px 25px;
  }
}
