.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 40px;
  background-color: #111;
  border-top: 1px solid rgba(251, 246, 246, 0.42);
  font-family: "Oswald", Arial, sans-serif;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  color: rgba(231, 230, 230, 0.738);
}

.footer__text {
  margin: 0;
}

.footer__links {
  display: flex;
  align-items: center;
  gap: 24px;
}

.footer__links :hover {
  cursor: pointer;
  color: #6563ff;
}

.footer__buttons {
  display: flex;
  gap: 40px;
}

.footer__button {
  background-color: transparent;
  border: none;
  font-family: "BebasNeue", Arial, sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: whitesmoke;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
}

.footer__icons {
  display: flex;
  gap: 24px;
}

.footer__icon-button {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 11px 40px;
  }

  .footer__links {
    gap: 10px;
  }

  .footer__icons {
    gap: 10px;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    padding: 7px 25px;
  }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .footer {
    flex-direction: column-reverse;
    font-size: 12px;
    line-height: 17px;
  }
}
