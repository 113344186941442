.preloader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.preloader-image {
  height: 400px;
  width: 400px;
}

.preloader-text {
  margin: 15px 0 0 30px;
  color: #4f4f4f;
  font-family: "Oswald", Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
}
