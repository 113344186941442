@font-face {
  font-family: "BebasNeue";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/BebasNeue-Regular.woff") format("woff"),
    url("./fonts/BebasNeue-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/Merriweather-Light.woff") format("woff"),
    url("./fonts/Merriweather-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/Merriweather-LightItalic.woff") format("woff"),
    url("./fonts/Merriweather-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Oswald-VariableFont_wght.woff") format("woff"),
    url("./fonts/Oswald-VariableFont_wght.woff2") format("woff2");
}
