.about {
  min-height: 70vh;
  padding: 40px 70px;
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  background-color: #111;
}

.about__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about__avatar-image {
  width: 450px;
  height: 450px;
  font-size: 30px;
  color: #fff;
  background: none;
  border: none;
  border-radius: 50%;
  position: relative;
  z-index: 0;
  transition: 0.3s;
}

.about__avatar-image::before {
  content: "";
  z-index: 0;
  position: absolute;
  inset: -15px;
  padding: 10px;
  border-radius: 50%;
  background: conic-gradient(
    #ffe3fec0,
    #0000 30deg 120deg,
    #ffdaf0 150deg 180deg,
    #0000 210deg 300deg,
    #ffd3fcdc 330deg
  );
  -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: intersect;
}

.about__avatar-image::after {
  content: "";
  position: absolute;
  inset: -100px;
  z-index: 0;
  background: radial-gradient(
      200px at left 400px top 150px,
      #fbd3f1 100%,
      #0000
    ),
    radial-gradient(200px at right 400px bottom 150px, #fccfefe6 100%, #0000);
  filter: blur(137px);
  opacity: 0.5;
}

.about__avatar-image::before,
.about__avatar-image::after {
  transition: 0.5s, 99999s 99999s transform;
}

.about__avatar-image:hover {
  box-shadow: 0 0 0 1px #666;
}

.about__avatar-image:hover::before,
.about__avatar-image:hover::after {
  transform: rotate(36000deg);
  transition: 0.5s, 600s linear transform;
}

.about__avatar-image::before {
  background-color: #222;
  border: 2px solid #333;
}

.about__avatar-image > img {
  border-radius: 50%;
  left: -2px;
  position: relative;
  top: 2px;
  width: 444px;
  height: 444px;
  opacity: 1;
  z-index: 99;
}

.about__text {
  margin-left: 60px;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 40px;
  color: whitesmoke;
}

.about__text-author {
  margin: 0;
  font-family: "BebasNeue", Arial, sans-serif;
  font-size: 52px;
  line-height: 57px;
  font-weight: 400;
  color: rgba(231, 230, 230, 0.738);
}

.about__text-bio {
  margin: 0;
  font-family: "Oswald", Arial, sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

@media screen and (max-width: 1003px) {
  .about__container {
    flex-direction: column;
  }

  .about__text-author {
    font-size: 40px;
    line-height: 45px;
  }

  .about__text {
    gap: 5px;
    margin: 60px 0 0 0;
    text-align: center;
  }

  .about__avatar-image {
    width: 406px;
    height: 406px;
  }

  .about__avatar-image > img {
    width: 400px;
    height: 400px;
    left: 3px;
  }
}

@media screen and (max-width: 556px) {
  .about {
    padding: 40px 26px;
  }

  .about__avatar-image {
    width: 310px;
    height: 310px;
  }

  .about__avatar-image > img {
    width: 300px;
    height: 300px;
    left: 5px;
    top: 5px;
  }
}

@media screen and (min-width: 320px) and (max-width: 376px) {
  .about__avatar-image {
    width: 268px;
    height: 268px;
  }

  .about__avatar-image > img {
    width: 262px;
    height: 262px;
    left: 5px;
    top: 5px;
  }
}
