.project_container {
  position: relative;
  width: 40rem;
  height: 24rem;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 10px 40px 5px rgba(215, 211, 211, 0.25);
  font-family: "Oswald", Arial, sans-serif;
  color: rgba(60, 60, 60, 0.685);
}

.project_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.project_content {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 21px;
  opacity: 0;
  padding: 7px 24px 10px;
  /* transition: opacity 0.3s ease-out; */
  width: 93%;
  background-color: rgb(255, 255, 255, 0.8);
  transform: translateY(40px); /* Initial position below the container */
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}

.project_title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.project_title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  transition: margin 0.3s 0.3s ease-out;
}

.project_card-links {
  display: flex;
  align-items: center;
  gap: 10px;
}

.project__icon-button {
  width: 32px;
  height: 32px;
}

.project__icon-button_github {
  width: 37px;
  height: 37px;
}

.project_description,
.project_link {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.project_description {
  margin: 9px 0 0;
  font-family: "Merriweather", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.project_link {
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.project_container:hover .project_content,
.project_container:hover .project_description {
  opacity: 1;
  /* transition: opacity 0.5s 0.1s ease-in; */
  transform: translateY(0); /* Slide the content up into view */
  transition: opacity 0.5s 0.1s ease-in, transform 0.3s 0.1s ease-in;
}

.project_container:hover .project_title {
  transition: margin 0.3s ease-out;
}

.project_container:hover .project_image {
  opacity: 0.2;
  transition: opacity 0.3s ease-in;
}

@media screen and (min-width: 1262px) and (max-width: 1409px) {
  .project_container {
    width: 35rem;
    height: 21rem;
  }
}

@media screen and (min-width: 938px) and (max-width: 1261px) {
  .project_container {
    width: 25rem;
    height: 15rem;
  }
}

@media screen and (max-width: 937px) {
  .project_container {
    width: 35rem;
    height: 21rem;
  }
}

@media screen and (max-width: 653px) {
  .project_container {
    width: 35rem;
    height: 19rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 583px) {
  .project_container {
    width: 21rem;
    height: 12rem;
  }

  .project_content {
    padding: 3px 10px 3px;
    width: 94%;
  }

  .project_title-wrapper {
    margin-bottom: -6px;
  }

  .project_title {
    font-size: 15px;
  }

  .project_description {
    margin: 5px 0 0;
    font-size: 12px;
  }

  .project_card-links {
    gap: 5px;
  }

  .project__icon-button {
    width: 24px;
    height: 24px;
  }

  .project__icon-button_github {
    width: 27px;
    height: 27px;
  }
}
