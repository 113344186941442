.spline-container {
  width: 100%;
  height: 84vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
}

.spline {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}

@media screen and (max-width: 517px) {
  .spline-container {
    height: 74vh;
  }
}
